<template>
    <div class="recordQueryBox">
        <div class="title">数据库检索</div>
        <div class="flex_l_c srhBox">
            <SelectTree class="selectBox" ref="menuParentTree"
                        :props="defaultProps"
                        :data="classifyList"
                        :value="searchForm.archivesBasicDataId"
                        :clearable="false"
                        :accordion="true"
                        @getValue="(value) => {searchForm.archivesBasicDataId=value}"/>
            <div class="p_r flex1">
                <el-input v-model="searchForm.keyword" class="el-input__inner2" clearable @focus="historyShow = true" @blur="srhIptBlur()" @input="historyShow = false" maxlength="50" placeholder="请输入关键词进行检索，多个关键词用空格分开（限50字）" @keyup.enter.native="search()"></el-input>
                <div class="historyBox" v-if="historyShow && historyList.length">
                    <div class="historyItemBox">
                        <div class="flex_b_c pointer item" v-for="(item, index) in historyList" :key="index" @click="selHistory(item)">
                            <div class="flex_l_c nameBox">
                                <div class="el-icon-time"></div>
                                <div class="omit name">{{item.keyword}}</div>
                            </div>
                            <div class="el-icon-close" @click.stop="cleanHistory(item)"></div>
                        </div>
                    </div>
                    <div class="delBtn">
                        <el-button type="text" @click="cleanAllHistory()">清空所有历史</el-button>
                    </div>
                </div>
            </div>
            <el-button type="primary" @click="search()">检索</el-button>
        </div>
    </div>
</template>

<script>
    import SelectTree from '@/components/treeSelect/treeSelect.vue'
    export default {
        components: {
            SelectTree
        },
        data() {
            return {
                defaultProps: {
                    value: 'id',
                    label: 'archivesBasicName',
                    children: 'children',
                },
                classifyList: [],
                searchForm: {
                    archivesBasicDataId: '',
                    keyword: ''
                },
                historyShow: false,
                historyList: [],
            }
        },
        mounted() {
            this.getClassifyList()
            this.getHistory()
        },
        methods: {
            // 检索
            search() {
                this.$router.push({path: '/record/query/databaseResult', query: this.searchForm})
            },
            // 获取档案分类
            getClassifyList() {
                this.$axios(this.api.record.selectArchivesBasicData).then((res) => {
                    if (res.status) {
                        this.classifyList = res.data
                        this.classifyList.unshift({
                            archivesBasicName: '全部',
                            id: '1',
                            children: []
                        })
                        this.$nextTick(() => {
                            this.searchForm.archivesBasicDataId = '1'
                        })
                    }
                })
            },
            // 获取历史记录
            getHistory() {
                this.$axios(this.api.record.getArchivesDocHistoryByCreateBy).then((res) => {
                    if (res.status) {
                        this.historyList = res.data
                    }
                })
            },
            // 清空历史
            cleanHistory(item) {
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.removeHistory + '/' + item.id, {}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                        } else {
                            this.$message.error('删除失败')
                        }
                        this.getHistory()
                    })
                })
            },
            // 清空所有历史
            cleanAllHistory() {
                this.$confirm(`确定清空所有历史吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.record.cleanArchivesDocHistory, {}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功')
                        } else {
                            this.$message.error('删除失败')
                        }
                        this.getHistory()
                    })
                })
            },
            // 输入框失焦
            srhIptBlur() {
                setTimeout(() => {
                    this.historyShow = false
                }, 200)
            },
            // 选择历史记录
            selHistory(item) {
                this.searchForm.keyword = item.keyword
                this.search()
            },
        },
    }
</script>

<style scoped>
    .recordQueryBox{
        margin: -10px;
        background: url('~@/assets/img/retrievalBg.png') no-repeat;
        background-size: 100% 100%;
        height: calc(100% + 20px) !important;
    }
</style>
